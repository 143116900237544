<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Locations
          <v-icon class="ml-2" :color="siteColor" @click="addLocation">mdi-map-marker-plus-outline</v-icon>
          <div class="flex-grow-1"></div>
          <v-text-field v-model="search" clearable label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          v-if="locations.length"
          :headers="headers"
          :items="locations"
          :items-per-page="10"
          item-key="id"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:body="{items}">
            <tbody>
              <template v-for="item in items">
                <tr :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>
                    <v-chip 
                      label
                      class="mr-1 my-1"
                      style="cursor:pointer;"
                      v-for="gw in getGateways(item)"
                      :key="gw"
                      @click.native.stop="viewGateway"
                      >{{ gw }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip 
                      label
                      class="mr-1 my-1"
                      style="cursor:pointer;"
                      v-for="sensor in item.sensors.items"
                      :key="sensor.id"
                      :color="checkStatus(sensor)"
                      @click.native.stop="routeTo(sensor.uuid)"
                      dark
                      >{{ sensor.name }}
                    </v-chip>
                  </td>
                  <td><v-select :menu-props="{ bottom: true, offsetX: true }" @click.native.stop="" @change="changeLocationStore({id: item.id, store: item.store.id})" :color="siteColor" single-line hide-details return-object :items="stores" item-text="name" v-model="item.store"></v-select></td>
                  <!-- <td>{{ item.store }}</td> -->
                  <td><v-icon color="red" @click="confirmDelete(item.id)">mdi-map-marker-remove-outline</v-icon></td>
                  <!-- <td>{{ item.owner ? item.owner.email : "" }}</td> -->
                  <!-- <td>{{ item.sensors ? item.sensors.items.length : "" }}</td> -->
                  <!-- <td>{{ item.users ? item.users.items.length : "" }}</td> -->
                  <!-- <td>{{ item.code ? item.code : "" }}</td> -->
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "locations",
  data() {
    return {
      search: "",
      sensors: [],
      currentLocation: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Gateways", value: "" },
        { text: "Sensors", value: "" },
        { text: "Store", value: "store", width: '20%' },
        { text: "", value: "", sortable: false, width: '3%' }
        // { text: "Owner", value: "" },
        // {
        //   text: "Sensors",
        //   value: "",
        //   sortable: false,
        //   // align: "end"
        // },
        // {
        //   text: "Users",
        //   value: "",
        //   sortable: false,
        // },
        // {
        //   text: "Code",
        //   value: "code",
        //   sortable: false,
        // }
      ]
    };
  },
  async created() {
    if (this.$route.params.loc) this.search = this.$route.params.loc
    await Promise.all([
      this.$store.dispatch("location/allLocations"),
      this.$store.dispatch("store/allStores")
    ]);
  },
  computed: {
    ...mapState("location", ["locations"]),
    ...mapState("store", ["stores"]),
    ...mapGetters("user", [
      "isSuper",
    ])
  },
  methods: {
    checkStatus(item) {
      let {alert, online, leak, alarm } = item
      // if (model === "LWL03A" || model === "LWL02") {
      //   if (leak) return 'red'
      //   else return '#009006'
      // }
      if (alert) return 'red'
      else if (!online || leak) return 'blue'
      else if (alarm) return 'orange'
      else return '#009006'
    },
    routeTo(route) {
      this.$router.push(`/device/${route}`)
    },
    checkSignalStrengthColor(rssi) {
      let strength = Number(rssi)
      return strength > -71 ? 'green' : strength <= -70 && strength >= -80 ? '#76dc00' : strength <= -81 && strength >= -90 ? '#d8d300' : 'red'
    },
    viewGateway(devEui) {
      if (!devEui.target.innerText) return
      let gw = devEui.target.innerText
      this.$router.push({ name: 'gateways', params: { gw } });
    },
    async changeLocationStore(location) {
      await this.$store.dispatch("location/updateLocation", location);
    },
    getGateways(item) {
      return Array.from(new Set(item.sensors.items.map(item => item.sensorGatewayId))).filter(item => item)
    },
    confirmDelete(id) {
      this.$swal({
      title: 'Remove Location',
      text: "Are you sure you want to remove this location?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      'cancelButtonText': 'No',
      'iconColor': '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteLocation(id)
        }
      });
    },
    async deleteLocation(id) {
      await this.$store.dispatch("location/removeLocation", id);
    },
    async addLocation() {
      this.$swal({
      title: 'Add Location',
      input: "text",
      inputLabel: "Location Name",
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add',
      'iconColor': '#d33',
      inputValue: "",
      inputValidator: (value) => {
        if (!value) {
          return "You need to enter a location name!";
        }
      }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch("location/addLocation", result.value);
        }
      });
      
    },
    setSensors(items, name) {
      this.currentLocation = name
      this.sensors = items
    },
    async approveUser(id, email) {
      await this.$store.dispatch("user/approveUserRegistration", { id, email });
    },
    async disableUser(id) {
      await this.$store.dispatch("user/disableUserRegistration", id);
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
table {
  td {
    .v-text-field {
      border-color: #ffffff1f;
      padding-top: 0px;
      margin-top: 0px;
      font-size: 14px;
    }
  }
}
</style>